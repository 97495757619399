import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from "gatsby"

import LogoEntreprises from './logoEntreprises'

class LogoClients extends React.Component {
  render() {
    const { data } = this.props
    const { edges: clients } = data.allMarkdownRemark

    return (
      <LogoEntreprises data={ clients }/>
    )
  }
}

LogoClients.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query LogoClientsQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "clients" } } }
          sort: { fields: [frontmatter___order], order: ASC }
        ) {
          totalCount
          edges {
            node {
              id
              frontmatter {
                title
                url
                logo { 
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 92) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <LogoClients data={data} />}
  />
)