/* eslint-disable */
import React from "react"
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Obfuscate from "react-obfuscate"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Content, { HTMLContent } from "../../components/Content"
import LogoClients from "../../components/logoClients"
import LogoPartenaires from "../../components/logoPartenaires"

const SocietePage = ({ data }) => {
  const { edges } = data.allMarkdownRemark
  const PostContent = HTMLContent || Content
  return (
    <Layout langKey={"en"}>
      <SEO
        title="The company Aprogsys - Your IT service provider in Franche-Comté - Software publisher"
        description="Aprogsys - Software development in Besançon (Doubs, France)"
        keywords={[`Computer Engineering`, `Besançon`]}
        jsonLd={[
          {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Our company",
                item: "https://www.aprogsys.com/en/societe/",
              },
            ],
          },
        ]}
      />
      <section>
        <div className="hero societe-banner">
          <div className="hero-body container">
            <div className="hero-title">
              <div className="columns is-centered">
                <div className="column is-6">
                  <div className="societe-banner-content">
                    <h1 className="is-size-3-touch"><span>The company Aprogsys</span></h1>
                    <div className="subtitle is-size-4-touch"><span>15 years of service and innovation in <strong>computer engineering</strong></span></div>
                  </div>
                </div>
                <div className="column is-4 schema-aprogsys">
                  <div className="is-size-4 is-hidden-touch" style={{position:'absolute',right: 60}}>
                    <p className="has-text-white" style={{position:'absolute',top:'170px', left: '-58px'}}>Application</p>
                    <p className="has-text-white" style={{position:'absolute',top:'30px', left:'130px'}}>Programming</p>
                    <p className="has-text-white" style={{position:'absolute',top:'198px', left:'230px'}}>Systems</p>
                    <svg style={{width: 300, height: 300, marginTop: -20}}>
                      <use xlinkHref="/img/societe-sprite.svg#icon-aprogsys-white" />
                    </svg>
                  </div>
                  <div className="is-size-5 is-hidden-desktop" style={{position:'relative', width:300, margin: '0 auto'}}>
                    <p className="has-text-white" style={{position:'absolute',top:'190px', left: '5px'}}>Application</p>
                    <p className="has-text-white" style={{position:'absolute',top:'60px', left:'130px'}}>Programming</p>
                    <p className="has-text-white" style={{position:'absolute',top:'218px', left:'210px'}}>Systems</p>
                    <svg style={{width: 300, height: 300}}>
                      <use xlinkHref="/img/societe-sprite.svg#icon-aprogsys-white" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container societe-description">
          <div className="columns is-multiline">
            <div className="column is-8-desktop is-full-touch">
              <h2 className="is-size-4-touch has-text-centered-touch">{edges[0].node.frontmatter.title}</h2> 
              <PostContent content={edges[0].node.html} className={"content"} />
            </div>
            <div className="column is-4-desktop is-full-touch has-text-centered">
              <svg className="is-hidden-touch" style={{width: 300, height: 300}}>
                <use xlinkHref="/img/societe-sprite.svg#icon-carte-france" />
              </svg>
              <svg className="is-hidden-desktop" style={{width: 200, height: 200}}>
                <use xlinkHref="/img/societe-sprite.svg#icon-carte-france" />
              </svg>
              <div style={{marginLeft:'30%'}}>
                <p className="has-text-left">14 rue la Fayette Bâtiment B<br/>25000 - Besançon</p>
                <p className="has-text-left" style={{ position: 'relative' }}>
                  <svg style={{ height: 25, width: 25, position: 'relative', top: 5, marginRight: '0.5rem' }}>
                    <use xlinkHref="/img/societe-sprite.svg#icon-tel" />
                  </svg>
                  +33 (0)3 81 47 70 38
                </p>
                <p className="has-text-left" style={{ position: 'relative' }}>
                  <svg style={{ height: 28, width: 28, position: 'relative', top: 5, marginRight: '0.5rem' }}>
                    <use xlinkHref="/img/societe-sprite.svg#icon-mail" />
                  </svg>
                  <Obfuscate email="aprogsys@aprogsys.com">aprogsys@aprogsys.com</Obfuscate>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="societe-direction">
        <div className="container">
          <h2 className="is-size-4-touch">Management</h2>
          <div className="columns is-multiline is-centered">
            {edges[0].node.frontmatter.direction.map((personne, key) => (
              <div className="column is-4" key={key}>
                <div className="media">
                  <figure className="media-left is-hidden-touch">
                    <svg className="perso" style={{width:150}}>
                      <use xlinkHref="/img/societe-sprite.svg#icon-manager" />
                    </svg>
                  </figure>
                  <div className="media-content">
                    <div className="content">
                      <p><strong>{personne.prenom}<br/>{personne.nom}</strong></p>
                      <p>{personne.poste}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section id="clients" className="section">
        <div className="container">
          <h2 className="is-size-4-touch">They trust us</h2>
          <LogoClients />
        </div>
      </section>
      {/*<section id="partenaires" className="section">
        <div className="container">
          <h2 className="is-size-4-touch">Partenaires :</h2>
          <LogoPartenaires />
        </div>
      </section> */}
      <section id="recrutement" className="societe-recrutement">
        <div className="container">
          <h2 className="is-size-4-touch">Recruitment</h2>
          <div className="content has-text-justified">
            <p>Join Aprogsys, join a young and dynamic team and reveal your talents!</p>
          </div>
          <div className="columns is-vcentered">
            <div className="column is-6">
              <div className="content has-text-justified">
                <div>Send us your application:</div>
                <div><span className="has-text-weight-bold">By e-mail :</span> <Obfuscate email="aprogsys@aprogsys.com" className="has-text-primary">aprogsys@aprogsys.com</Obfuscate></div>
              </div>
            </div>
            <div className="column is-6 is-hidden-touch">
              <svg style={{height:200}}>
                <use xlinkHref="/img/societe-sprite.svg#icon-recrute" />
              </svg>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

SocietePage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default SocietePage

export const SocietePageQuery = graphql`
query SocieteEnQuery {
  allMarkdownRemark(
    filter: { frontmatter: { templateKey: { eq: "societe" }, langKey: { eq: "en" } } }
    sort: { fields: [frontmatter___order], order: ASC }
  ) {
    totalCount
    edges {
      node {
        id
        html
        frontmatter {
          title
          direction {
            nom,
            prenom,
            poste
          }
        }
      }
    }
  }
}`